import React, {Component} from 'react';

class About extends Component {
    render() {
        const version = '1.053022';
        return (
            <div>
                <h2>About EmPowerYu</h2>
                <p>Version {version}</p>
            </div>
        );
    }
}

export default About;
