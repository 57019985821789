/*******
 * Copyright 2017-2021 - EmPowerYu, inc.
 *
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';

import { HomePageStrings as STRs } from '../shared/strings';
class CardFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checkState: false,
            selected: 0,
            handleFilterSelected: props.handleFilterSelected,
            handleCheckChange: props.handleAttentionChecked,
            handleViewChange: props.handleViewChange,
            fViewList: false
        };

        this.toggleCheckbox = this.toggleCheckbox.bind(this);
        this.handleButtonSelect = this.handleButtonSelect.bind(this);
        this.toggleListCards = this.toggleListCards.bind(this);
    }

    toggleListCards(e) {
        this.toggleCheckboxState("fViewList", e.target.value, this.state.handleViewChange);
    }

    toggleCheckbox(e) {
        this.toggleCheckboxState("checkState", e.target.value, this.state.handleCheckChange);
    }

    toggleCheckboxState(cbStateName, newState, handler) {
        let checkState = (newState === "on");
        if (this.state[cbStateName] === checkState) {
            checkState = !checkState;
        }

        let state = {};
        state[cbStateName] = checkState;
        this.setState(state);
        (handler)(checkState);
    }

    handleButtonSelect(e) {
        let filter = parseInt(e.target.value, 10);
        this.setState({ selected: filter });
        (this.state.handleFilterSelected)(filter);
    }

    render() {
        const buttonData = [
            { label: STRs.all },
            { label: STRs.level1 },
            { label: STRs.level2 },
            { label: STRs.level3 }
        ];
        let filterButtons = buttonData.map((btn, idx) => {
            let className = "filter-button";
            if (idx === this.state.selected) {
                className += " selected";
            }
            return ( <Button key={ btn.label } value={ idx } onClick={ this.handleButtonSelect }
                 className={ className }> { btn.label } </Button>
            )
        });

        const checkboxMarkup = (
            <div>
                <ul className="checkboxes">
                    <li>
                        <input type="checkbox" key="attention" onChange={ this.toggleCheckbox } checked={ this.state.checkState } />
                    </li>
                    <li className="attention-text" >{STRs.needsAttention}</li>
                    <li> 
                        <input type="checkbox" key="list" onChange={ this.toggleListCards } checked={ this.state.fViewList } />
                    </li>
                    <li className="attention-text" >{STRs.showList}</li>
                </ul>
            </div>
        )
        const markup = (
            <div id="button-header" >
                <div>
                    <div className="title" ><span>{STRs.monitoringLevel}</span></div>
                    {checkboxMarkup}
                </div> 
                <div> { filterButtons } </div> 
            </div >
        );

        return markup;
    }
}

CardFilter.propTypes = {
    filter: PropTypes.number,
    handleFilterSelected: PropTypes.func,
    handleAttentionChecked: PropTypes.func,
    handleViewChange: PropTypes.func,
}

export default CardFilter;
