/*******
 * Copyright 2017-2021 - EmPowerYu, inc.
 *
 */

const _localStorageAPIs = function () {
    return {

        setCaregiverId: function setCaregiverId(userName, organizationName, caregiverId, userType, inactivityTimeout) {
            localStorage.setItem('cgId', caregiverId);
            localStorage.setItem('userType', userType);
            localStorage.setItem('userName', userName);
            localStorage.setItem('organizationName', organizationName);
            localStorage.setItem('inactivityTimeout', inactivityTimeout);
        },

        getInactivityTimeout: function() {
            return localStorage.getItem("inactivityTimeout");
        },

        updateCaregiverId: function updateCaregiverId(caregiverId) {
            localStorage.setItem('cgId', caregiverId);
        },

        getCaregiverId: function getCaregiverId() {
            return localStorage.getItem("cgId");
        },

        setUserType: function setUserTyped(userType) {
            localStorage.setItem('userType', userType);
        },

        getUserType: function getUserType() {
            return localStorage.getItem("userType");
        },

        getUsername: function getUsername() {
            return localStorage.getItem("userName");
        },

        getOrganizationName: function getOrganizationName() {
            return localStorage.getItem("organizationName");
        },

        setPatientList: function setPatientList(pList, pObject) {
            let lTime = Date.now();
            localStorage.setItem('pList', JSON.stringify(pList));
            localStorage.setItem('pObject', JSON.stringify(pObject));
            localStorage.setItem('pListUpdateTime', lTime.toString())
        },

        getPatientList: function getPatientList() {
            return JSON.parse(localStorage.getItem("pList"));
        },

        getPatientObject: function getPatientObj() {
            return JSON.parse(localStorage.getItem("pObject"));
        },

        setPatientId: function (id) {
            if (typeof id === 'string') {
                localStorage.setItem('currentPID', JSON.stringify(id));
            }
        },

        getPatientId: function() {
            return localStorage.getItem('currentPID');
        },

        getPatientVUrl: function() {
            return localStorage.getItem('vUrl');
        },

        setPatientVUrl: function(vUrl) {
            localStorage.setItem('vUrl', vUrl);
        },

        removePatientVUrl: function() {
            localStorage.removeItem('vUrl');
        },

        resetLocalStorage: function resetLocalStorage() {
            localStorage.removeItem("cgId");
            localStorage.removeItem("pList");
            localStorage.removeItem("pListUpdateTime");
            localStorage.removeItem("userType");
            localStorage.removeItem('currentPID');
            localStorage.removeItem('userName');
            localStorage.removeItem('pObject');
            localStorage.removeItem('inactivityTimeout');
            localStorage.removeItem('vUrl');
        },
    }
};

const LocalStorageAPI = _localStorageAPIs();

export default LocalStorageAPI;
