/*******
 * Copyright 2017-2021 - EmPowerYu, inc.
 *
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CardFilter from './CardFilter';
import TableView from './TableView';
import PatientDataStorage   from '../DataService/DataService';

import { URLS }  from '../shared/urls';
import Utils from '../shared/Utilities';
import {
    BEHAVIORSTATUSICON, BEHAVIORSTATEICON, ENVIRONMENTALSTATUSICON,
    PHYSIOLOGYSTATUSICON, CONNECTIONSTATUSICON, CONNECTIONSTATUS_ENUM,
    BEHAVIORSTATE_ENUM, INACTIVITY_TIMEOUT
} from '../shared/constants';
import {PatientCardStrings as STRs, HomePageStrings as HSTRs } from '../shared/strings';
import blank from '../images/blank.gif';
import LoadingSpinner from "../shared/lib/LoadingSpinner";
import CareGiverServices from "../DataService/CareGiverService";

class PatientCardBody extends Component {
    constructor(props) {
        super(props);

        // the embedded <br/> below is because we can't have HTML in the 
        // string table.  React prevents it from being displayed as HTML.
        // See https://stackoverflow.com/questions/39758136/render-html-string-as-real-html-in-a-react-component
        let connectionStatusText = [
            HSTRs.disconnected + "<br/>",
            HSTRs.systemConnected,
            HSTRs.sensorIssue,
        ];

        this.state = {
            patient: props.patient,
            connectionStatusText: connectionStatusText,
            behaviorStateIcon: BEHAVIORSTATEICON,
            behaviorStatusIcon: BEHAVIORSTATUSICON,
            environmentalStatusIcon: ENVIRONMENTALSTATUSICON,
            physiologyStatusIcon: PHYSIOLOGYSTATUSICON,
            connectionStatusIcon: CONNECTIONSTATUSICON
        };
    }

    buildRow1(patient) {
        let rowLayout;
        switch (patient.patientState) {
            case 1:
                if (patient.ConnectedStatus === 1) {
                    rowLayout = this.row1Layout1(patient);
                } else {
                    rowLayout = this.row1Layout2(patient);
                }
                break;


            case 0:
            case 2:
            case 3:
            default:
                rowLayout = this.row1Layout2(patient);
                break;
        }

        return rowLayout;
    }

    row1Layout1(patient) {
        let conStatus = this.state.connectionStatusText[patient.connectedStatus];
        let constatusIcon = "status-block2 " + this.state.connectionStatusIcon[patient.connectedStatus];

        let lastConnectTime = Utils.calculateLastActivity(patient.systemDisconnectTime);

        let layout =
            <tr className='status-container'>
                    <td colSpan="3" className="connection-status">
                        <div>
                            <img src={blank} className={constatusIcon} alt={HSTRs.patientActivityStatus} />
                        </div>
                        <div><span dangerouslySetInnerHTML={{__html: conStatus}} /><br /> </div>
                        <div>{lastConnectTime }</div>
                    </td>
            </tr>;

        return layout;
    }

    row1Layout2(patient) {
        let conStatus = this.state.connectionStatusText[patient.connectedStatus];
        let conStatusIcon = "status-block2 " + this.state.connectionStatusIcon[patient.connectedStatus];
        let behaviorState = "status-block " + this.state.behaviorStateIcon[patient.behaviorState];
        let lastConnectTime = Utils.calculateLastActivity(patient.systemDisconnectTime);

        let layout =
            <tr className="status-container">
                <td colSpan="1">
                    <img src={blank} className={behaviorState} alt={HSTRs.patientActivityStatus}/>
                </td>
                <td colSpan="2">
                    <img src={blank} className={conStatusIcon} alt={HSTRs.systemStatus}/>&nbsp;
                    <div className="connection-status">
                        <span dangerouslySetInnerHTML={{__html: conStatus}} /> {lastConnectTime}
                    </div>
                </td>
            </tr>;

        return layout;
    }

    buildRow2(patient) {
        let rowLayout;
        switch (patient.patientState) {
            case 1:
                rowLayout = this.row2Layout1(patient);
                break;

            case 0:
            case 2:
            case 3:
                rowLayout = this.row2Layout2(patient);
                break;
            default:

        }
        return rowLayout;
    }

    row2Layout1() {
        //let statusText = <span><span>status</span><br /><strong>Normal</strong></span>;
        let rowLayout = (
            <tr className="status-container">
                <td colSpan="2">
                    <table>
                        <tbody>
                        <tr>
                            <td className="connection-status">
                                <img src={blank} className="status-block imgConcernLow" alt={HSTRs.statusIsGood} />
                            </td>
                            <td>
                                <span>{STRs.status} </span><strong> {STRs.normal}</strong>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </td>
                <td>&nbsp;</td>
            </tr>
        );

        return rowLayout;
    }

    row2Layout2(patient) {
        let behaviorStatusIcon = "status-block " + this.state.behaviorStatusIcon[patient.behaviorStatus];
        let physiologyStatusIcon = "status-block " + this.state.physiologyStatusIcon[patient.physiologyStatus];
        let environStatusIcon = "status-block " + this.state.environmentalStatusIcon[patient.environmentalStatus];

        let icon1 = <div><img src={blank} className={behaviorStatusIcon} alt={HSTRs.behavioralStatus}/></div>;
        let icon2 = <div><img src={blank} className={environStatusIcon} alt={HSTRs.environStatus}/></div>;
        let icon3 = <div><img src={blank} className={physiologyStatusIcon} alt={HSTRs.phsysiologyStatus}/></div>;

        let row2;
        if (patient.monitoringLevel === 1) {
            row2 = <tr className="status-container"><td>{icon1}</td><td>{icon2}</td><td>&nbsp;</td></tr>;
        } else {
            row2 = <tr className="status-container"><td>{icon1}</td><td>{icon2}</td><td>{icon3}</td></tr>;
        }

        return row2;
    }

    render() {
        let patient=this.state.patient;

        let row1 = this.buildRow1(patient);
        let row2 = this.buildRow2(patient);

        return (
            <div className="card-body">
                <img className='patient-image' src={patient.patientImage} alt={patient.patientName} />
                <div>
                    <table>
                        <tbody>
                        {row1}
                        {row2}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

PatientCardBody.propTypes = {
    patient: PropTypes.any
}

class PatientFooter extends Component {
    constructor(props) {
        super(props);
        this.state = {patient: props.patient};
    }

    render() {
        let patient = this.state.patient;
        let lastActivityString = Utils.calculateLastActivity(patient.lastActivityTime);
        let activity = (lastActivityString  === "") ?
            <strong>{STRs.notAvailable}</strong> : lastActivityString;
        let activityState = !((patient.connectedStatus === CONNECTIONSTATUS_ENUM.connected ) &&
                              (patient.behaviorState === BEHAVIORSTATE_ENUM.active));
        let activityText = activityState ? (<span>{STRs.lastActivity}: {activity}</span>) : '';

        let footer =
            <div className="card-footer">
                <div>{STRs.level} {patient.monitoringLevel}</div>
                <div>{activityText}</div>
            </div>;

        return footer;
    }
}

PatientFooter.propTypes = {
    patient: PropTypes.any
}

class PatientCard extends Component {
    constructor(props) {
        super(props);
        this.state = {patient: props.patient, caregiverId: props.caregiverId, history: props.history};
        this.handlePatientClick = this.handlePatientClick.bind(this);
    }

    handlePatientClick() {
        let patientId = this.state.patient.patientID;
        CareGiverServices.setCurrentPatientId(patientId);
        let location = {
            pathname: URLS.patientCardDetails,
            state: { patientId: patientId, caregiverId: this.state.caregiverId, timezone: this.state.patient.timezone }
        };

        this.state.history.push( location );
    }

    render() {
        let patient = this.state.patient;
        // console.log(`rendering patient card for ${patient.patientName}`);
        const headerColors = {
            "0": "unknown",
            "1": "positive",
            "2": "alert",
            "3": "negative"
        };

        let color = patient.patientState.toString();
        let titleColor = "card-header title-color-" + headerColors[color];

        return (
            <div className='card-container'>
                <div className='patient-card' onClick={this.handlePatientClick}>
                    <div className={ titleColor }>{patient.patientName}</div>
                    <PatientCardBody patient={patient}/>
                    <PatientFooter patient={patient} />
                </div>
            </div>
        )
    }
}

PatientCard.propTypes = {
    patient: PropTypes.any,
    history: PropTypes.any,
    caregiverId: PropTypes.string
}

class PatientCards extends Component {
    constructor(props) {
        super(props);
        this.state = {patientList: [],
            caregiverId: props.caregiverId,
            filter: 0,
            attentionChecked: false,
            fViewList: false,
            summaryData: undefined,
            history: props.history,
            timeoutId: -1
        };
        this.handleFilterSelect = this.handleFilterSelect.bind(this);
        this.handleAttentionCheckbox = this.handleAttentionCheckbox.bind(this);
        this.handleViewChange = this.handleViewChange.bind(this);
        this.handlePatientListUpdate = this.handlePatientListUpdate.bind(this);
    }

    componentDidMount() {
        // get our data
        PatientDataStorage.getPatientList(this.state.caregiverId, this.handlePatientListUpdate);
    }

    componentWillUnmount() {
        console.log('componentWillUnmount: PatientCards');
        if (this.state.timeoutId !== -1) {
            clearTimeout(this.state.timeoutId);
        }
    }

    updateTimeout() {
        console.log('PatientCards timeout update');
        if (this.state.timeoutId !== -1) {
            clearTimeout(this.state.timeoutId);
        }
        let timeoutId = setTimeout( () => {
            Utils.cleanupGoToLogin(this.state.history, true);
        }, INACTIVITY_TIMEOUT);
        PatientDataStorage.keepAlive();
        return timeoutId;
    }

    handleAttentionCheckbox(checkedState) {
        let filterList = PatientDataStorage.getFilteredPatientList(this.state.filter, checkedState);
        let timeoutId = this.updateTimeout();
        this.setState( {attentionChecked: checkedState, patientList: filterList, timeoutId} );
    }

    handleViewChange(checkState) {
        //alert(checkState);
        let timeoutId = this.updateTimeout();
        this.setState({fViewList: checkState, timeoutId});
    }
    handleFilterSelect(filter) {
        let filterList = PatientDataStorage.getFilteredPatientList(filter, this.state.attentionChecked);
        let timeoutId = this.updateTimeout();
        this.setState({filter: filter, patientList: filterList, timeoutId} );
    }

    handlePatientListUpdate( patientList, responseStatus ) {
        // data acquired
        if (responseStatus === 200) {
            let summaryData = PatientDataStorage.getPatientSummary();
            let timeoutId = this.updateTimeout();
            this.setState({ patientList: patientList,
                init: true,
                timeoutId,
                summaryData: summaryData,
            });

        } else if (responseStatus === 401) {
            Utils.cleanupGoToLogin(this.state.history);
        } else {
            alert('Error loading Patient Data');
            this.setState({patientList: []});
        }
    }

    buildListView() {
        return (
            <div>
                <TableView summaryData={this.state.summaryData} patientList={this.state.patientList}
                           caregiverId={this.state.caregiverId} history={this.state.history}/>
            </div>
        )
    }

    buildCardMarkup() {
        let list;
        let patients = this.state.patientList;
        let caregiverId = this.state.caregiverId;
        let history = this.state.history;

        if (patients.length === 0) {
            list = <div>No patients have this monitoring level</div>
        } else {
            list = [];
            let row, card1, card2;
            for (let i = 0, len = patients.length; i < len; i += 2) {
                card1 = <PatientCard key={patients[i].patientID} patient={patients[i]} history={history} caregiverId={caregiverId} />;
                card2 = (i + 1) < len
                    ? <PatientCard key={patients[i + 1].patientID} patient={patients[i + 1]} history={history} caregiverId={caregiverId} />
                    : "";

                row = (
                    <div key={i} className="row">
                        <div className="col-xs-12 col-md-6 ">{card1}</div>
                        <div className="col-xs-12 col-md-6 ">{card2}</div>
                    </div>
                );
                list.push(row);
            }
        }

        return (
                <div className="MPY-card-container animated fadeInUp delay-1">
                    <div className="container-fluid">{list}</div>
                </div>
        );
    }

    buildFilter() {
        return (
            <CardFilter filter={this.state.filter}
                        handleFilterSelected={this.handleFilterSelect}
                        handleAttentionChecked={this.handleAttentionCheckbox}
                        handleViewChange={this.handleViewChange} />
        );
    }

    render() {
        let list, filter;
        let patients = this.state.patientList;

        filter = <div></div>;
        if (patients.length === 0 && !this.state.init) {
            list = <LoadingSpinner />;
        } else if (this.state.fViewList) {
            filter = this.buildFilter();
            list = this.buildListView();
        } else {
            filter = this.buildFilter();
            list = this.buildCardMarkup();
        }
        return (
            <div>
                {filter}
                {list}
                <div className="clearFix"></div>
            </div>
        );
    }
}

PatientCards.propTypes = {
    patient: PropTypes.any,
    history: PropTypes.any,
    caregiverId: PropTypes.string

}
export default PatientCards
